import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Animation1 from "../../Components/Animation1/Animation1";
import "./Home.scss";

const Home = () => {
  useEffect(() => {
    // Intersection Observer to add 'visible' class when sections are in view
    const sections = document.querySelectorAll(".section");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    sections.forEach((section) => {
      observer.observe(section);
    });
  }, []);

  return (
    <div className="home">
      <div className="banner">
        <div className="banner-gradient-horizontal"></div>
        <div className="banner-gradient-vertical"></div>
        <div className="banner-animation-1">
          <Animation1 />
        </div>
        <div className="banner-text">
          <h1>Revolutionizing India's Drone Industry</h1>
          <p>
            AI-Powered Autonomous Drone Solutions Transforming Agriculture,
            Defense, and Enterprise.
          </p>

          <div className="banner-buttons">
            <Link to="/services" className="primary-button">
              About Us
            </Link>
            <Link to="/about" className="secondary-button">
              Contact Us
            </Link>
          </div>
        </div>
        {/* <div className="banner-drone-pic">
          url("")
        </div> */}
      </div>

      <div className="home-sections">
        {/* Drone and Charging Stage Idea */}
        <div className="section-1 section">
          <h2>Our Drone and Charging Stage Innovation</h2>
          <p>
            Our advanced autonomus drones are designed for various applications, from
            agriculture to defense and custom enterprise usage. One of our most unique
            innovations is the charging stage for drones, ensuring prolonged
            operational efficiency. By utilizing advanced AI and machine
            learning techniques, we aim to develop autonomous drones capable of
            performing complex tasks with minimal human intervention. Our drones
            will autonomously navigate, take measurements, and make decisions in
            real-time, optimizing their usage across different sectors. This,
            combined with the induction based charging stage concept, allows for the efficient
            and continuous deployment of drones for long-term operations without
            the need for constant manual intervention. It’s the future of
            autonomous drone systems, and we aim build it today.
          </p>
        </div>

        {/* Categories of Drones */}
        <div className="section-2 section">
          <h2>Drone Categories We’re Innovating For</h2>
          <p>
            Agriculture: Precision farming, crop monitoring, and seeding needs.
            <br></br>
            Enterprise: Warehouse management, infrastructure inspection, autonomus surveillance and
            logistics.<br></br>
            Defense: Surveillance, reconnaissance, and security operations.
            <br></br>
            Environment: Disaster relief, environmental monitoring, and wildlife
            protection.<br></br>
            Entertainment: Aerial photography, cinematography, and live-event
            coverage.
          </p>
        </div>

        {/* About INFERBYTE */}
        <div className="section-3 section">
          <h2>About Inferbyte Technologies</h2>
          <p>
            We are an Indian startup that focuses on
            building AI-powered autonomous drone solutions for a variety of
            industries, including agriculture, defense, enterprise, and
            environmental sectors. With a deep understanding of both hardware
            and software, we are poised to revolutionize industries with our
            innovative approach to drone technology. Our team is a unique blend
            of AI experts, engineers, and entrepreneurs who are passionate about
            creating intelligent solutions for the future. Whether it’s creating
            AI-driven systems for autonomous flight or optimizing drone
            performance, we are dedicated to pushing the boundaries of
            technology.
          </p>
          <Link to="/about" className="btn">
            Learn More About Us
          </Link>
        </div>

        {/* Looking for Seed Funds */}
        <div className="section-4 section">
          <h2>Seeking Seed Funding for Prototype Development</h2>
          <p>
            We are currently seeking seed funds to develop our prototype and
            bring our vision of autonomous, AI-powered drones with self-charging platforms to life. The funds
            will be utilized for product development, R&D, and prototyping
            stages to make our drone technology ready for real-world
            applications. If you are an investor looking for an exciting
            opportunity in the growing drone and AI industry, we invite you to
            be part of this transformative journey. Join us in revolutionizing
            industries through the power of artificial intelligence and
            autonomous systems.
          </p>
          <Link to="/contact" className="btn">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
