import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./Invest.scss";
// import Animation2 from "../../Components/Animation2/Animation2";

const Invest = () => {

  // <div className="background">
    //   <div className="background-animation">
    //     <Animation2 />
    //   </div>
    // </div>
    
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    // Redirect to the Contact Us page after the component mounts
    navigate("/contact");
  }, [navigate]);

  return <div className=""></div>; // You can keep this empty or add a loading indicator
};

export default Invest;

