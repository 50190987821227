import React from "react";
import "./About.scss";
import Animation2 from "../../Components/Animation2/Animation2";

const About = () => {
  return (
    <div className="about">
      <div className="about-background-animation">
        <Animation2 />
      </div>
      <div className="about-container">
        <h1>About Inferbyte Technologies</h1>

        <p>
          We are an AI-driven technology startup focused on revolutionizing the
          drone industry. Established in 2024, we are in the early stages of
          developing cutting-edge drone solutions for agriculture, defense, and
          enterprise applications. We want to create high-quality,
          cost-effective drones that cater to India’s needs and expand to global
          markets.
        </p>

        <p>
          At this stage, we are actively refining our product ideas and seeking
          seed funding to bring our vision to life. With a focus on autonomous
          systems and AI-driven analytics, we aim to position ourselves as a
          established name in India as well as global drone industry.
        </p>

        <p>
          {" "}
          Our drone solutions are designed with cutting-edge AI-powered systems
          that enable autonomous functionality, ensuring precise navigation,
          efficient flight, and task execution without human intervention. With
          features like induction-based charging and real-time AI analytics, our
          drones are built to offer unmatched performance in mission-critical
          applications across agriculture, defense, and enterprise sectors.{" "}
        </p>

        <p>
          {" "}
          At this stage, our target market includes Indian farmers and
          agribusinesses seeking efficient agricultural tools, defense agencies
          requiring surveillance drones, and enterprise clients in logistics,
          construction, and infrastructure. We are focused on creating
          affordable, high-quality solutions that not only cater to India's
          growing needs but also position us to expand into global markets in
          the near future.{" "}
        </p>

        <p>
          Join us as we shape the future of drone technology. Together, we can
          build a smarter, more innovative and a better tomorrow.
        </p>

        {/* <div className="about-section">
          <h2>Meet Our Team</h2>
          <p>Our team is composed of experienced professionals with diverse backgrounds in computer science, engineering, and business. Together, we bring a wealth of knowledge and expertise to every project, working collaboratively to deliver exceptional results.</p>
        </div> */}
      </div>
    </div>
  );
};

export default About;
